export const competirorsFinderConfig = {
    headers: [
        {
            text: 'Logo',
            sortable: false,
            value: 'logo',
            width: '76px',
            align: 'left',
            headingClass: 't-cell w76',
            intercomAnchor: 'competitors-finder-logo',
        },
        {
            text: 'Application',
            sortable: false,
            value: 'title',
            sortBy: 'title',
            align: 'left',
            width: 'calc(100% - 636px)',
            textWidth: '270px',
            headingClass: 'app-column t-cell w350',
            intercomAnchor: 'competitors-finder-title',
        },
        {
            text: 'Competitor',
            value: 'tools',
            align: 'left',
            largePadding: true,
            sortable: false,
            intercomAnchor: 'competitors-finder-tools',
            headingClass: 't-cell w140',
        },
        {
            text: 'Publisher',
            value: 'publisher',
            sortBy: 'publisher',
            align: 'left',
            sortable: false,
            textWidth: '200px',
            intercomAnchor: 'competitors-finder-publisher',
            headingClass: 't-cell w140',
        },
        {
            text: 'Category',
            value: 'categories',
            sortBy: 'categories',
            align: 'left',
            sortable: false,
            textWidth: '200px',
            intercomAnchor: 'competitors-finder-category',
            headingClass: 't-cell w140',
        },
        {
            text: 'Rate / Ratings',
            value: 'rating',
            sortBy: 'rating',
            align: 'left',
            sortable: false,
            intercomAnchor: 'competitors-finder-ratings',
            headingClass: 't-cell w160',
        },
        {
            text: 'Installs',
            value: 'installs',
            sortBy: 'installs',
            align: 'left',
            sortable: false,
            intercomAnchor: 'competitors-finder-installs',
            headingClass: 't-cell w140',
        },
        {
            text: 'Revenue',
            value: 'revenue',
            sortBy: 'revenue',
            align: 'left',
            sortable: false,
            intercomAnchor: 'competitors-finder-revenue',
            headingClass: 't-cell w140',
        }
    ],
}